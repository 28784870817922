import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress
} from "@mui/material";
import RecommendationComponent from "../components/Recomendation";
import axios from "axios";

const AssessmentComponent = ({ contentId, onAssessmentSubmit, assessments }) => {
  console.log(contentId)
  console.log(assessments);
  // const quizArray = [
  //   {
  //     "question_id": 1,
  //     "question": "What is the unit of electric charge?",
  //     "options": [
  //       "Volt",
  //       "Coulomb",
  //       "Ampere",
  //       "Ohm"
  //     ],
  //     "correct": 1
  //   },
  //   {
  //     "question_id": 2,
  //     "question": "What is Coulomb's law?",
  //     "options": [
  //       "The force between two charges is directly proportional to the product of the charges and inversely proportional to the square of the distance between them.",
  //       "The energy in a system is conserved.",
  //       "The force between two charges is inversely proportional to the product of the charges.",
  //       "The force between two charges is independent of the distance between them."
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 3,
  //     "question": "What is the value of the permittivity of free space (ε0)?",
  //     "options": [
  //       "8.85 x 10^-12 C^2/N·m^2",
  //       "9.81 x 10^-12 C^2/N·m^2",
  //       "7.50 x 10^-12 C^2/N·m^2",
  //       "6.63 x 10^-12 C^2/N·m^2"
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 4,
  //     "question": "What is the principle of superposition in electrostatics?",
  //     "options": [
  //       "The total force on a charge is the vector sum of the forces exerted by all other charges.",
  //       "The total force on a charge is the scalar sum of the forces exerted by all other charges.",
  //       "The total potential energy is zero in a system of charges.",
  //       "The total force on a charge is inversely proportional to the sum of the distances from other charges."
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 5,
  //     "question": "What is the electric field inside a conductor in electrostatic equilibrium?",
  //     "options": [
  //       "Zero",
  //       "Constant and non-zero",
  //       "Equal to the charge density",
  //       "Equal to the potential difference"
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 6,
  //     "question": "What is the work done in moving a charge between two points on an equipotential surface?",
  //     "options": [
  //       "Zero",
  //       "Equal to the potential difference",
  //       "Equal to the charge density",
  //       "Equal to the electric field"
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 7,
  //     "question": "What is the relationship between electric field (E) and electric potential (V)?",
  //     "options": [
  //       "E = V / d",
  //       "E = dV / dx",
  //       "E = V * d",
  //       "E = V + d"
  //     ],
  //     "correct": 1
  //   },
  //   {
  //     "question_id": 8,
  //     "question": "Which of the following describes the potential energy of a charge in an electric field?",
  //     "options": [
  //       "Potential energy is the work done to move the charge from infinity to that point.",
  //       "Potential energy is the product of the charge and the electric field.",
  //       "Potential energy is the force experienced by the charge.",
  //       "Potential energy is independent of the position of the charge."
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 9,
  //     "question": "What happens to the electric field when a dielectric is introduced between two charges?",
  //     "options": [
  //       "The electric field decreases.",
  //       "The electric field increases.",
  //       "The electric field remains unchanged.",
  //       "The electric field becomes zero."
  //     ],
  //     "correct": 0
  //   },
  //   {
  //     "question_id": 10,
  //     "question": "What is the unit of electric potential?",
  //     "options": [
  //       "Coulomb",
  //       "Volt",
  //       "Newton",
  //       "Joule"
  //     ],
  //     "correct": 1
  //   }
  // ];

  const quizArray = assessments?.map((row, index) => ({
    // question_id: row.content_id,
    question: row.question,
    options: [row.option1, row.option2, row.option3, row.option4],
    correct: row.correct
  })
  );
  const questionsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(quizArray.length).fill("")
  );
  const [score, setScore] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [loader, setLoader] = useState(false)
  const [showResult, setShowResult] = useState(false);
  const [explaination, setExplaination] = useState(null)
  const [showRecommendation, setShowRecommendation] = useState(false);

  const isPageAnswered = selectedOptions
    .slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage)
    .every((option) => option !== "");

  const handleOptionChange = async (event, questionIndex) => {
    setExplaination(null);
    const selectedOption = parseInt(event.target.value, 10);
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[questionIndex] = selectedOption;
    setSelectedOptions(newSelectedOptions);

    const question = quizArray[questionIndex];
    const options = question.options;

    if (selectedOption === question.correct) {
      setIsCorrect(true);
    } else {
      setLoader(true);
      try {
        let response = await axios.post('/api/content/getExplanation', {
          question: question.question,
          options: options.map((option, index) => ({ [index]: option })),
          selectedOption: options[selectedOption],
        })
        console.log(response)
        if (!response) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data =  response.data
        setExplaination(data.explaination);
        setIsCorrect(false);
      } catch (error) {
        console.error("Error fetching explanation:", error);
        setExplaination("An error occurred while generating the explanation. Please try again.");
        setIsCorrect(false);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleNextClick = () => {
    setExplaination('')
    if (isCorrect) {
      setScore(score + 1);
    }
    const totalPages = Math.ceil(quizArray.length / questionsPerPage);
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setShowResult(true);
    }
  };

  const handleFinishClick = () => {
    setShowRecommendation(true);
  };

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #FF6652;
    border-radius: 10px;
            padding:10px
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a0c7dc;
  }
`;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const renderQuestions = () => {
    const startIndex = currentPage * questionsPerPage;
    const endIndex = Math.min(startIndex + questionsPerPage, quizArray.length);

    return quizArray.slice(startIndex, endIndex).map((assessment, index) => (
      <div
        key={startIndex + index}
        style={{
          backgroundColor: " #fbf1ea",
          padding: "16px",
          margin: "16px 0",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {assessment.question}
        </Typography>
        <RadioGroup
          value={selectedOptions[startIndex + index]}
          onChange={(event) => handleOptionChange(event, startIndex + index)}
          name={`question${startIndex + index}`}
        >
          {assessment.options.map((option, i) => (
            <FormControlLabel
              key={i}
              value={i}
              control={<Radio />}
              label={option}
            />
          ))}
          {explaination ? <div className="border-2 p-2 rounded-lg border-[#FF6652]">
            <p className="custom-scrollbar h-60 overflow-auto ">
              {explaination}
            </p>
          </div> : loader ? <CircularProgress className="text-center mx-auto" /> : ''}
        </RadioGroup>
      </div>
    ));
  };

  return (
    <Container maxWidth="lg">
      {showRecommendation ? (
        <RecommendationComponent score={score} outOf={quizArray.length} />
      ) : (
        <>
          {quizArray.length > 0 ? (
            renderQuestions()
          ) : (
            <div style={{ marginTop: 100, marginBottom: 100, marginLeft: 80 }}>
              <Typography variant="h6">
                Sorry, no assessments available.
              </Typography>
            </div>
          )}
          {quizArray.length > 0 && !showResult && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={handleNextClick}
                disabled={!isPageAnswered}
                style={{ backgroundColor: "#FF6652", color: "#FFFF" }}
              >
                {currentPage === Math.ceil(quizArray.length / questionsPerPage) - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          )}
          {showResult && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={handleFinishClick}
                style={{ backgroundColor: "#0A033C", color: "#FFFF" }}
              >
                Finish
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default AssessmentComponent;